import { useEffect } from "react";
import Aos from "aos";
import ImageLogoLarge from "./assets/images/img-logo-large.png";
import ImageDog1 from "./assets/images/img-dog-1.gif";
import ImageDog2 from "./assets/images/img-dog-2.gif";
import ImageDog3 from "./assets/images/img-dog-3.gif";
import ImageDog4 from "./assets/images/img-dog-4.gif";
import ImageToken from "./assets/images/img-token.gif";
import ImageH2B from "./assets/images/img-h2b.png";
import ImageSoon from "./assets/images/img-soon.png";
import ImageTitleFooter from "./assets/images/img-title-footer.png";
import ImageFooter1 from "./assets/images/img-footer-1.png";
import ImageFooter2 from "./assets/images/img-footer-2.png";
import "./App.scss";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);

  return (
    <div className="App">
      <main className="page-main">
        <section className="section-intro">
          <div data-aos="fade-up" className="container wrapper">
            <p className="value">CA: TBA</p>
            <div className="btn-groups">
              <a href="" className="btn btn-buy">
                BUY NOW
              </a>
              <a href="" className="btn btn-chart">
                CHART
              </a>
            </div>
          </div>
        </section>
        <section className="section-about">
          <div data-aos="fade-up" className="container wrapper">
            <div className="img-left">
              <img src={ImageLogoLarge} alt="" />
            </div>
            <div className="content">
              <p className="title">
                WHAT IS <span>GAMESBONK?</span>
              </p>
              <p className="paragraph">
                Welcome to GameSBonk – where memes meet money and the fun never
                stops!
              </p>
              <p className="paragraph">
                Ever wondered what happens when Bonk, the king of crypto, teams
                up with GameStop, the legend of gaming? Well, wonder no more!
                GameSBonk is the result of this epic collision!
              </p>
              <p className="paragraph">
                Buckle up and join us on this wild ride as we redefine the
                boundaries of crypto and gaming, one meme at a time!
              </p>

              <div className="btn-groups-h">
                <a
                  href="https://t.me/GameSbonk"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-tele"
                >
                  TELEGRAM
                </a>
                <a
                  href="https://twitter.com/GameSbonkX"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-x"
                >
                  TWITTER
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section-image">
          <div data-aos="" className="container">
            <div className="img-dog">
              <img src={ImageDog1} alt="" />
            </div>
            <div className="img-dog">
              <img src={ImageDog2} alt="" />
            </div>
            <div className="img-dog">
              <img src={ImageDog3} alt="" />
            </div>
            <div className="img-dog">
              <img src={ImageDog4} alt="" />
            </div>
          </div>
        </section>
        <section className="section-tokenomics">
          <div className="container wrapper">
            <div data-aos="fade-up" className="content-1">
              <p className="title-content">TOKENOMICS</p>
              <ul className="list-token">
                <li className="item">
                  <div className="img-token">
                    <img src={ImageToken} alt="" />
                  </div>
                  <div className="content">
                    <p className="title">TOTAL SUPPLY</p>
                    <p className="value">100M</p>
                  </div>
                </li>
                <li className="item">
                  <div className="img-token">
                    <img src={ImageToken} alt="" />
                  </div>
                  <div className="content">
                    <p className="title">BUY - SELL TAX </p>
                    <p className="value">0% - 0%</p>
                  </div>
                </li>
                <li className="item">
                  <div className="img-token">
                    <img src={ImageToken} alt="" />
                  </div>
                  <div className="content">
                    <p className="title">LIQUIDITY</p>
                    <p className="value">BURNT</p>
                  </div>
                </li>
              </ul>
            </div>
            <div data-aos="fade-up" className="content-2">
              <div className="left">
                <p className="title">
                  How To Buy <span>$GMEB?</span>
                </p>
                <ul className="list">
                  <li>
                    <p className="h2b">1. CONNECT YOUR WALLET</p>
                    <p className="des">
                      Plug in your wallet to Raydium or trading Bot like you're
                      charging your phone for an epic gaming session!
                    </p>
                  </li>
                  <li>
                    <p className="h2b">2. Find $GMEB Token</p>
                    <p className="des">
                      Scroll through the crypto goodies and pick
                      <span> $GMEB </span>– it's the star of this show!
                    </p>
                  </li>
                  <li>
                    <p className="h2b">2. Swap SOL for $GMEB</p>
                    <p className="des">
                      Time to make the magic happen! Swap your SOL for
                      <span> $GMEB </span>and watch the bonks roll in.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="right">
                <img src={ImageH2B} alt="" />
              </div>
            </div>
            <div data-aos="fade-up" className="content-3">
              <img src={ImageSoon} alt="" />
              <p>Game coming soon!</p>
            </div>
          </div>
        </section>
      </main>
      <footer className="page-footer">
        <div className="img-left">
          <img src={ImageFooter1} alt="" />
        </div>
        <div className="img-right">
          <img src={ImageFooter2} alt="" />
        </div>
        <div className="container wrapper">
          <div data-aos="fade-up" className="content">
            <div className="main">
              <div className="img-title">
                <img src={ImageTitleFooter} alt="" />
              </div>
              <div className="btn-groups">
                <a
                  href="https://t.me/GameSbonk"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-tele"
                >
                  TELEGRAM
                </a>
                <a
                  href="https://twitter.com/GameSbonkX"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-x"
                >
                  TWITTER
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>COPYRIGHT © 2024 - ALL RIGHTS RESERVED BY Gamesbonk</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
